import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// TODO: antd has findDomNode issues in strict mode. Rather than go back, this will clean up the console logs for development
// https://github.com/ant-design/ant-design/issues/26136
// eslint-disable-next-line no-console
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (errObj, ...args) => {
    if (process.env.NODE_ENV === 'development' && (typeof errObj.message === 'string' || typeof errObj === 'string') && args.includes('findDOMNode')) {
        return;
    }
    consoleError(errObj, ...args);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
