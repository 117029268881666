import { useTheme, View, Image, Text } from '@aws-amplify/ui-react';
import React from 'react';
import logo from './app-logo-128x128.png';

export const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image alt="ByteChek logo" src={logo} />
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={`${tokens.colors.neutral['80']}`}>&copy; All Rights Reserved</Text>
            </View>
        );
    },
};
