import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AuditorCompany = {
  __typename?: 'AuditorCompany';
  companyType: CompanyType;
  domainName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ByteChekCompany = {
  __typename?: 'ByteChekCompany';
  companyType: CompanyType;
  domainName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Company = AuditorCompany | ByteChekCompany | CustomerCompany;

export type CompanyPage = {
  __typename?: 'CompanyPage';
  items: Array<Company>;
  pageInfo: PageInfo;
};

export enum CompanyType {
  Auditor = 'Auditor',
  ByteChek = 'ByteChek',
  Customer = 'Customer'
}

export type CreateAuditorCompany = {
  domainName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCustomerCompany = {
  auditorCompanyId?: InputMaybe<Scalars['String']>;
  domainName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type CreateUser = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups: Array<Scalars['String']>;
  lastName: Scalars['String'];
};

export type CustomerCompany = {
  __typename?: 'CustomerCompany';
  auditor?: Maybe<AuditorCompany>;
  companyType: CompanyType;
  domainName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum DeliveryChannelType {
  Email = 'Email'
}

export type DomainNameCheck = {
  __typename?: 'DomainNameCheck';
  domainName: Scalars['String'];
  invalidReason?: Maybe<Scalars['String']>;
  isAvailable: Scalars['Boolean'];
  isValid: Scalars['Boolean'];
};

export type Filter = {
  attributeNames: Scalars['AWSJSON'];
  attributeValues: Scalars['AWSJSON'];
  expression: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAuditorCompany: AuditorCompany;
  createCustomerCompany: CustomerCompany;
  createUser: User;
  deleteUser: User;
  updateSelf: User;
  updateUser: User;
  updateUserNotificationSetting: UserNotificationSetting;
};


export type MutationCreateAuditorCompanyArgs = {
  input: CreateAuditorCompany;
};


export type MutationCreateCustomerCompanyArgs = {
  input: CreateCustomerCompany;
};


export type MutationCreateUserArgs = {
  input: CreateUser;
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateSelfArgs = {
  input: UpdateSelf;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input: UpdateUser;
};


export type MutationUpdateUserNotificationSettingArgs = {
  input: UpdateUserNotificationSetting;
  notificationTypeId: Scalars['String'];
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  nextPageKey?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  checkDomainName: DomainNameCheck;
  companies: CompanyPage;
  company?: Maybe<Company>;
  myCompany: ByteChekCompany;
  notificationTypes: Array<NotificationType>;
  self: User;
  user?: Maybe<User>;
  userNotificationSettings: Array<UserNotificationSetting>;
  users: UserPage;
};


export type QueryCheckDomainNameArgs = {
  companyType: CompanyType;
  domainName: Scalars['String'];
};


export type QueryCompaniesArgs = {
  filter?: InputMaybe<Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  nextPageKey?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Filter>;
  limit?: InputMaybe<Scalars['Int']>;
  nextPageKey?: InputMaybe<Scalars['String']>;
};

export type UpdateSelf = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateUser = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UpdateUserNotificationSetting = {
  deliveryChannelTypes: Array<DeliveryChannelType>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  deliveryChannelTypes: Array<DeliveryChannelType>;
  notificationTypeId: Scalars['String'];
};

export type UserPage = {
  __typename?: 'UserPage';
  items: Array<User>;
  pageInfo: PageInfo;
};

export type CreateAuditorCompanyMutationVariables = Exact<{
  input: CreateAuditorCompany;
}>;


export type CreateAuditorCompanyMutation = { __typename?: 'Mutation', createAuditorCompany: { __typename?: 'AuditorCompany', id: string, name: string, domainName: string, companyType: CompanyType } };

export type CreateCustomerCompanyMutationVariables = Exact<{
  input: CreateCustomerCompany;
}>;


export type CreateCustomerCompanyMutation = { __typename?: 'Mutation', createCustomerCompany: { __typename?: 'CustomerCompany', id: string, name: string, domainName: string, companyType: CompanyType, auditor?: { __typename?: 'AuditorCompany', id: string, name: string, domainName: string, companyType: CompanyType } | null } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUser;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } };

export type UpdateSelfMutationVariables = Exact<{
  input: UpdateSelf;
}>;


export type UpdateSelfMutation = { __typename?: 'Mutation', updateSelf: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateUser;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } };

export type UpdateUserNotificationSettingMutationVariables = Exact<{
  notificationTypeId: Scalars['String'];
  input: UpdateUserNotificationSetting;
}>;


export type UpdateUserNotificationSettingMutation = { __typename?: 'Mutation', updateUserNotificationSetting: { __typename?: 'UserNotificationSetting', notificationTypeId: string, deliveryChannelTypes: Array<DeliveryChannelType> } };

export type CheckDomainNameQueryVariables = Exact<{
  domainName: Scalars['String'];
  companyType: CompanyType;
}>;


export type CheckDomainNameQuery = { __typename?: 'Query', checkDomainName: { __typename?: 'DomainNameCheck', domainName: string, isAvailable: boolean, isValid: boolean, invalidReason?: string | null } };

export type CompaniesQueryVariables = Exact<{
  nextPageKey?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Filter>;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyPage', items: Array<{ __typename?: 'AuditorCompany', id: string, name: string, domainName: string, companyType: CompanyType } | { __typename?: 'ByteChekCompany', id: string, name: string, domainName: string, companyType: CompanyType } | { __typename?: 'CustomerCompany', id: string, name: string, domainName: string, companyType: CompanyType, auditor?: { __typename?: 'AuditorCompany', id: string, name: string, domainName: string, companyType: CompanyType } | null }>, pageInfo: { __typename?: 'PageInfo', nextPageKey?: string | null } } };

export type CompanyQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'AuditorCompany', id: string, name: string, domainName: string, companyType: CompanyType } | { __typename?: 'ByteChekCompany', id: string, name: string, domainName: string, companyType: CompanyType } | { __typename?: 'CustomerCompany', id: string, name: string, domainName: string, companyType: CompanyType, auditor?: { __typename?: 'AuditorCompany', id: string, name: string, domainName: string, companyType: CompanyType } | null } | null };

export type MyCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCompanyQuery = { __typename?: 'Query', myCompany: { __typename?: 'ByteChekCompany', id: string, name: string, domainName: string, companyType: CompanyType } };

export type NotificationTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationTypesQuery = { __typename?: 'Query', notificationTypes: Array<{ __typename?: 'NotificationType', id: string, name: string }> };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } };

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string } | null };

export type UserNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationSettingsQuery = { __typename?: 'Query', userNotificationSettings: Array<{ __typename?: 'UserNotificationSetting', notificationTypeId: string, deliveryChannelTypes: Array<DeliveryChannelType> }> };

export type UsersQueryVariables = Exact<{
  nextPageKey?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Filter>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserPage', items: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string }>, pageInfo: { __typename?: 'PageInfo', nextPageKey?: string | null } } };


export const CreateAuditorCompanyDocument = gql`
    mutation CreateAuditorCompany($input: CreateAuditorCompany!) {
  createAuditorCompany(input: $input) {
    id
    name
    domainName
    companyType
  }
}
    `;
export type CreateAuditorCompanyMutationFn = Apollo.MutationFunction<CreateAuditorCompanyMutation, CreateAuditorCompanyMutationVariables>;

/**
 * __useCreateAuditorCompanyMutation__
 *
 * To run a mutation, you first call `useCreateAuditorCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAuditorCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAuditorCompanyMutation, { data, loading, error }] = useCreateAuditorCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAuditorCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateAuditorCompanyMutation, CreateAuditorCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAuditorCompanyMutation, CreateAuditorCompanyMutationVariables>(CreateAuditorCompanyDocument, options);
      }
export type CreateAuditorCompanyMutationHookResult = ReturnType<typeof useCreateAuditorCompanyMutation>;
export type CreateAuditorCompanyMutationResult = Apollo.MutationResult<CreateAuditorCompanyMutation>;
export type CreateAuditorCompanyMutationOptions = Apollo.BaseMutationOptions<CreateAuditorCompanyMutation, CreateAuditorCompanyMutationVariables>;
export const CreateCustomerCompanyDocument = gql`
    mutation CreateCustomerCompany($input: CreateCustomerCompany!) {
  createCustomerCompany(input: $input) {
    id
    name
    domainName
    companyType
    auditor {
      id
      name
      domainName
      companyType
    }
  }
}
    `;
export type CreateCustomerCompanyMutationFn = Apollo.MutationFunction<CreateCustomerCompanyMutation, CreateCustomerCompanyMutationVariables>;

/**
 * __useCreateCustomerCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCustomerCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerCompanyMutation, { data, loading, error }] = useCreateCustomerCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerCompanyMutation, CreateCustomerCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerCompanyMutation, CreateCustomerCompanyMutationVariables>(CreateCustomerCompanyDocument, options);
      }
export type CreateCustomerCompanyMutationHookResult = ReturnType<typeof useCreateCustomerCompanyMutation>;
export type CreateCustomerCompanyMutationResult = Apollo.MutationResult<CreateCustomerCompanyMutation>;
export type CreateCustomerCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCustomerCompanyMutation, CreateCustomerCompanyMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUser!) {
  createUser(input: $input) {
    id
    email
    firstName
    lastName
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    id
    email
    firstName
    lastName
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateSelfDocument = gql`
    mutation UpdateSelf($input: UpdateSelf!) {
  updateSelf(input: $input) {
    id
    email
    firstName
    lastName
  }
}
    `;
export type UpdateSelfMutationFn = Apollo.MutationFunction<UpdateSelfMutation, UpdateSelfMutationVariables>;

/**
 * __useUpdateSelfMutation__
 *
 * To run a mutation, you first call `useUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfMutation, { data, loading, error }] = useUpdateSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelfMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfMutation, UpdateSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfMutation, UpdateSelfMutationVariables>(UpdateSelfDocument, options);
      }
export type UpdateSelfMutationHookResult = ReturnType<typeof useUpdateSelfMutation>;
export type UpdateSelfMutationResult = Apollo.MutationResult<UpdateSelfMutation>;
export type UpdateSelfMutationOptions = Apollo.BaseMutationOptions<UpdateSelfMutation, UpdateSelfMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $input: UpdateUser!) {
  updateUser(id: $id, input: $input) {
    id
    email
    firstName
    lastName
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserNotificationSettingDocument = gql`
    mutation UpdateUserNotificationSetting($notificationTypeId: String!, $input: UpdateUserNotificationSetting!) {
  updateUserNotificationSetting(
    notificationTypeId: $notificationTypeId
    input: $input
  ) {
    notificationTypeId
    deliveryChannelTypes
  }
}
    `;
export type UpdateUserNotificationSettingMutationFn = Apollo.MutationFunction<UpdateUserNotificationSettingMutation, UpdateUserNotificationSettingMutationVariables>;

/**
 * __useUpdateUserNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationSettingMutation, { data, loading, error }] = useUpdateUserNotificationSettingMutation({
 *   variables: {
 *      notificationTypeId: // value for 'notificationTypeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNotificationSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNotificationSettingMutation, UpdateUserNotificationSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNotificationSettingMutation, UpdateUserNotificationSettingMutationVariables>(UpdateUserNotificationSettingDocument, options);
      }
export type UpdateUserNotificationSettingMutationHookResult = ReturnType<typeof useUpdateUserNotificationSettingMutation>;
export type UpdateUserNotificationSettingMutationResult = Apollo.MutationResult<UpdateUserNotificationSettingMutation>;
export type UpdateUserNotificationSettingMutationOptions = Apollo.BaseMutationOptions<UpdateUserNotificationSettingMutation, UpdateUserNotificationSettingMutationVariables>;
export const CheckDomainNameDocument = gql`
    query CheckDomainName($domainName: String!, $companyType: CompanyType!) {
  checkDomainName(domainName: $domainName, companyType: $companyType) {
    domainName
    isAvailable
    isValid
    invalidReason
  }
}
    `;

/**
 * __useCheckDomainNameQuery__
 *
 * To run a query within a React component, call `useCheckDomainNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDomainNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDomainNameQuery({
 *   variables: {
 *      domainName: // value for 'domainName'
 *      companyType: // value for 'companyType'
 *   },
 * });
 */
export function useCheckDomainNameQuery(baseOptions: Apollo.QueryHookOptions<CheckDomainNameQuery, CheckDomainNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDomainNameQuery, CheckDomainNameQueryVariables>(CheckDomainNameDocument, options);
      }
export function useCheckDomainNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDomainNameQuery, CheckDomainNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDomainNameQuery, CheckDomainNameQueryVariables>(CheckDomainNameDocument, options);
        }
export type CheckDomainNameQueryHookResult = ReturnType<typeof useCheckDomainNameQuery>;
export type CheckDomainNameLazyQueryHookResult = ReturnType<typeof useCheckDomainNameLazyQuery>;
export type CheckDomainNameQueryResult = Apollo.QueryResult<CheckDomainNameQuery, CheckDomainNameQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($nextPageKey: String, $limit: Int, $filter: Filter) {
  companies(nextPageKey: $nextPageKey, limit: $limit, filter: $filter) {
    items {
      ... on AuditorCompany {
        id
        name
        domainName
        companyType
      }
      ... on ByteChekCompany {
        id
        name
        domainName
        companyType
      }
      ... on CustomerCompany {
        id
        name
        domainName
        companyType
        auditor {
          id
          name
          domainName
          companyType
        }
      }
    }
    pageInfo {
      nextPageKey
    }
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      nextPageKey: // value for 'nextPageKey'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: String!) {
  company(id: $id) {
    ... on AuditorCompany {
      id
      name
      domainName
      companyType
    }
    ... on ByteChekCompany {
      id
      name
      domainName
      companyType
    }
    ... on CustomerCompany {
      id
      name
      domainName
      companyType
      auditor {
        id
        name
        domainName
        companyType
      }
    }
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const MyCompanyDocument = gql`
    query MyCompany {
  myCompany {
    id
    name
    domainName
    companyType
  }
}
    `;

/**
 * __useMyCompanyQuery__
 *
 * To run a query within a React component, call `useMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCompanyQuery(baseOptions?: Apollo.QueryHookOptions<MyCompanyQuery, MyCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCompanyQuery, MyCompanyQueryVariables>(MyCompanyDocument, options);
      }
export function useMyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCompanyQuery, MyCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCompanyQuery, MyCompanyQueryVariables>(MyCompanyDocument, options);
        }
export type MyCompanyQueryHookResult = ReturnType<typeof useMyCompanyQuery>;
export type MyCompanyLazyQueryHookResult = ReturnType<typeof useMyCompanyLazyQuery>;
export type MyCompanyQueryResult = Apollo.QueryResult<MyCompanyQuery, MyCompanyQueryVariables>;
export const NotificationTypesDocument = gql`
    query NotificationTypes {
  notificationTypes {
    id
    name
  }
}
    `;

/**
 * __useNotificationTypesQuery__
 *
 * To run a query within a React component, call `useNotificationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationTypesQuery(baseOptions?: Apollo.QueryHookOptions<NotificationTypesQuery, NotificationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationTypesQuery, NotificationTypesQueryVariables>(NotificationTypesDocument, options);
      }
export function useNotificationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationTypesQuery, NotificationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationTypesQuery, NotificationTypesQueryVariables>(NotificationTypesDocument, options);
        }
export type NotificationTypesQueryHookResult = ReturnType<typeof useNotificationTypesQuery>;
export type NotificationTypesLazyQueryHookResult = ReturnType<typeof useNotificationTypesLazyQuery>;
export type NotificationTypesQueryResult = Apollo.QueryResult<NotificationTypesQuery, NotificationTypesQueryVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const UserDocument = gql`
    query User($id: String!) {
  user(id: $id) {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserNotificationSettingsDocument = gql`
    query UserNotificationSettings {
  userNotificationSettings {
    notificationTypeId
    deliveryChannelTypes
  }
}
    `;

/**
 * __useUserNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useUserNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>(UserNotificationSettingsDocument, options);
      }
export function useUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>(UserNotificationSettingsDocument, options);
        }
export type UserNotificationSettingsQueryHookResult = ReturnType<typeof useUserNotificationSettingsQuery>;
export type UserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useUserNotificationSettingsLazyQuery>;
export type UserNotificationSettingsQueryResult = Apollo.QueryResult<UserNotificationSettingsQuery, UserNotificationSettingsQueryVariables>;
export const UsersDocument = gql`
    query Users($nextPageKey: String, $limit: Int, $filter: Filter) {
  users(nextPageKey: $nextPageKey, limit: $limit, filter: $filter) {
    items {
      id
      email
      firstName
      lastName
    }
    pageInfo {
      nextPageKey
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      nextPageKey: // value for 'nextPageKey'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;