import React, { useState } from 'react';
import { Alert, Button, Form, Input, Modal, Select, Table, Tooltip } from 'antd';
import { CheckCircleOutlined, CloudSyncOutlined, RocketOutlined, SaveOutlined } from '@ant-design/icons';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { getDomain } from 'tldjs';
import {
    CompaniesDocument,
    CompanyType,
    useCheckDomainNameLazyQuery,
    useCompaniesQuery,
    useCreateCustomerCompanyMutation,
    useCreateAuditorCompanyMutation,
} from '../graphql/generated';

function getRootDomain(): string | null {
    const { host } = window.location;
    const domain = getDomain(host);
    if (!domain) return null;
    return host.replace('admin.', '');
}

export default function Companies() {
    // State and Server Data
    const [domainChecked, setDomainChecked] = useState<boolean>(false);
    const [domainError, setDomainError] = useState<string | undefined | null>();
    const { loading, data } = useCompaniesQuery({ variables: { limit: 100 } });
    const [checkDomainNameQuery] = useCheckDomainNameLazyQuery();
    const [saveNewCustomerCompany] = useCreateCustomerCompanyMutation({ refetchQueries: [CompaniesDocument] });
    const [saveNewAuditorCompany] = useCreateAuditorCompanyMutation({ refetchQueries: [CompaniesDocument] });
    const [customerCompanyTypeSelected, setCustomerCompanyTypeSelected] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [addForm] = Form.useForm();

    // Functions for components
    async function handleCheckDomainName(): Promise<void> {
        const row = await addForm.getFieldsValue();
        const domain = getRootDomain();
        if (!row.subDomain) return;
        if (!row.companyType) {
            setDomainChecked(false);
            setDomainError('Please select the company type before checking the subdomain availability');
        }
        const domainName =
            row.companyType === CompanyType.Auditor ? `${row.subDomain}.auditor.${domain || 'bytechek.dev'}` : `${row.subDomain}.${domain || 'bytechek.dev'}`;
        const result = await checkDomainNameQuery({
            variables: { companyType: row.companyType, domainName },
        });
        if (result && result.data && result.data.checkDomainName.isAvailable && result.data.checkDomainName.isValid) {
            setDomainChecked(true);
            setDomainError(undefined);
        } else {
            setDomainChecked(false);
            setDomainError(result.data?.checkDomainName.invalidReason);
        }
    }
    const clear = () => {
        addForm.resetFields();
        setIsModalVisible(false);
        setDomainChecked(false);
        setDomainError(undefined);
    };
    const onValuesChangeHandler = (changedValues: { subDomain: string }) => {
        if (changedValues.subDomain) {
            setDomainChecked(false);
            setDomainError(undefined);
        }
    };
    const onCompanyTypeChange = (value: CompanyType) => {
        if (value === CompanyType.Customer) {
            setCustomerCompanyTypeSelected(true);
        } else {
            setCustomerCompanyTypeSelected(false);
        }
    };
    const handleOk = async () => {
        const row = await addForm.validateFields();
        const domain = getRootDomain();
        if (row.companyType === CompanyType.Customer) {
            await saveNewCustomerCompany({
                variables: {
                    input: {
                        name: row.name,
                        domainName: `${row.subDomain}.${domain || 'bytechek.dev'}`,
                        email: row.email,
                        firstName: row.firstName,
                        lastName: row.lastName,
                        auditorCompanyId: row.auditorCompanyId,
                    },
                },
            });
        } else if (row.companyType === CompanyType.Auditor) {
            await saveNewAuditorCompany({
                variables: {
                    input: {
                        name: row.name,
                        domainName: `${row.subDomain}.auditor.${domain || 'bytechek.dev'}`,
                        email: row.email,
                        firstName: row.firstName,
                        lastName: row.lastName,
                    },
                },
            });
        }

        clear();
    };
    const handleModalCancel = () => {
        clear();
    };

    // Table Definitions
    const columns = [
        { dataIndex: 'id', title: 'ID', responsive: ['md'] as Breakpoint[] },
        {
            dataIndex: 'name',
            title: 'Company Name',
            editable: false,
        },
        {
            dataIndex: 'domainName',
            title: 'Domain Name',
            editable: false,
        },
        {
            dataIndex: 'companyType',
            title: 'Company Type',
            editable: false,
            responsive: ['md'] as Breakpoint[],
        },
        {
            dataIndex: ['auditor', 'name'],
            title: 'Auditor Company',
            editable: false,
            responsive: ['md'] as Breakpoint[],
        },
    ];

    return (
        <>
            <Button
                icon={<RocketOutlined />}
                onClick={() => {
                    setIsModalVisible(true);
                }}
                type="primary"
                style={{ marginBottom: 16 }}
            >
                Add New Company
            </Button>
            <Table rowKey="id" loading={loading} bordered dataSource={data ? data.companies.items : []} columns={columns} />
            <Modal
                title="Add Company"
                visible={isModalVisible}
                onOk={handleOk}
                okButtonProps={{ disabled: !domainChecked, icon: <SaveOutlined /> }}
                okText="Save"
                onCancel={handleModalCancel}
            >
                <Form
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 12 }}
                    form={addForm}
                    component={false}
                    onValuesChange={(changedValues) => onValuesChangeHandler(changedValues)}
                >
                    <Form.Item
                        label="Name of Company"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: `Company Name is Required`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Type of Company"
                        name="companyType"
                        rules={[
                            {
                                required: true,
                                message: `Company Type is Required`,
                            },
                        ]}
                    >
                        <Select placeholder="Select Company Type" defaultActiveFirstOption onChange={(value) => onCompanyTypeChange(value)}>
                            <Select.Option value={CompanyType.Customer}>Customer</Select.Option>
                            <Select.Option value={CompanyType.Auditor}>Auditor</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Sub Domain"
                        name="subDomain"
                        rules={[
                            {
                                required: true,
                                message: `Sub Domain is Required`,
                            },
                        ]}
                    >
                        <span style={{ display: 'flex' }}>
                            <Input />
                            {domainChecked ? (
                                <CheckCircleOutlined style={{ color: 'green', paddingLeft: 10, fontSize: 30 }} />
                            ) : (
                                <Tooltip placement="topRight" title="Check Sub Domain Availability">
                                    <CloudSyncOutlined
                                        style={{ paddingLeft: 10, fontSize: 30, cursor: 'pointer' }}
                                        onClick={async () => {
                                            await handleCheckDomainName();
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </span>
                    </Form.Item>
                    <Form.Item
                        label="First Name of Administrator"
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: `First Name is Required`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Last Name of Administrator"
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: `Last Name is Required`,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email of Administrator"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Not a Valid Email',
                            },
                            {
                                required: true,
                                message: `Email Address is required`,
                            },
                        ]}
                    >
                        <Input placeholder="name@example.com" />
                    </Form.Item>
                    {customerCompanyTypeSelected && (
                        <Form.Item
                            label="Auditor Company"
                            name="auditorCompanyId"
                            rules={[
                                {
                                    required: true,
                                    message: `An Auditor Company is required`,
                                },
                            ]}
                        >
                            <Select placeholder="Select Auditor">
                                {data?.companies.items
                                    .filter((company) => company.companyType === CompanyType.Auditor)
                                    .map((filteredCompany) => (
                                        <Select.Option key={filteredCompany.id} value={filteredCompany.id}>
                                            {filteredCompany.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form>
                <span hidden={!domainError}>
                    <Alert message="Error" description={domainError} type="error" showIcon />
                </span>
            </Modal>
        </>
    );
}
