import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { User } from '../graphql/generated';

// TODO: Fix the Type to be generic and able to be passed in.
export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: string;
    inputType: 'number' | 'text';
    record: User;
    index: number;
    children: React.ReactNode;
}

export function EditableCell({ editing, dataIndex, title, inputType, children, ...restProps }: EditableCellProps) {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
}
