import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache } from '@apollo/client';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { components } from './AuthCustomComponents';
import PersistentDrawerLeft from './Drawer/Drawer';
import './Auth.css';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
    },
});

function App() {
    return (
        <Authenticator loginMechanisms={['email']} components={components} hideSignUp>
            {({ user }) => {
                const httpLink = new HttpLink({
                    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql',
                });
                const authMiddleware = new ApolloLink((operation, forward) => {
                    // add the authorization to the headers
                    operation.setContext(({ headers = {} }) => ({
                        headers: {
                            ...headers,
                            authorization: `Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}`,
                        },
                    }));

                    return forward(operation);
                });
                const client = new ApolloClient({
                    cache: new InMemoryCache(),
                    link: concat(authMiddleware, httpLink),
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: 'cache-and-network',
                        },
                    },
                });
                const sdkConfig: SplitIO.IBrowserSettings = {
                    core: {
                        authorizationKey: process.env.REACT_APP_SPLIT_IO_AUTHKEY as string,
                        key: user.username ? user.username : 'no-user-name',
                    },
                };
                return (
                    <SplitFactory config={sdkConfig}>
                        <ApolloProvider client={client}>
                            <BrowserRouter>
                                <PersistentDrawerLeft />
                            </BrowserRouter>
                        </ApolloProvider>
                    </SplitFactory>
                );
            }}
        </Authenticator>
    );
}

export default App;
